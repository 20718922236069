<template>
  <v-container fluid class="main-layout">
    <v-row no-gutters>
      <v-col lg="6" v-show="$vuetify.breakpoint.mdAndUp">
        <background-image/>
      </v-col>
      <v-col col="6" class="form-container">
        <form class="md-layout complete-profile">
          <div style="width: 70%;">
            <v-container>
              <v-row
                justify="center"
              >
                <v-col 
                  justify="center"
                  cols="12"
                >
                  <a href="#/" class="img-style">
                    <img :src="logoImg" width="180px" />
                  </a>
                  <div class="title">Just one more step to go</div>
                </v-col>
              </v-row>
            </v-container>

            <md-card-content class="data-container">
              <div class="md-layout md-gutter">
                <v-col cols="12" md="12" lg="12">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                        <md-input 
                          name="email" 
                          id="email"  
                          class="input-data"
                          v-model="model.email"
                          :disabled="true"
                        />
                    </md-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <md-field  :class="getValidationClass('firstName')">
                        <md-input 
                          name="first-name" 
                          id="first-name"  
                          class="input-data"
                          v-model="model.firstName"
                          placeholder="First Name"
                        />
                      <span class="md-error" v-if="firstNameValidationError">{{
                          firstNameValidationError
                        }}</span>
                    </md-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('lastName')">
                        <md-input 
                          name="last-name" 
                          id="last-name"  
                          class="input-data"
                          v-model="model.lastName" 
                          placeholder="Last Name"
                        />
                        <span class="md-error" v-if="lastNameValidationError">{{
                          lastNameValidationError
                        }}</span>
                    </md-field>
                  </div>
                </v-col>

                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <md-field
                      id="brand-name"
                      class="field-margin"
                      :class="getValidationClass('brandName')"
                    >
                      <md-input
                        class="input-data"
                        name="brand-name"
                        v-model="model.brandName"
                        placeholder="Business name"
                      />
                      <span class="md-error" v-if="brandNameValidationError">{{
                          brandNameValidationError
                        }}</span>
                    </md-field>
                  </div>
                </v-col>

                <v-col cols="12" md="6" lg="6">
                  <div class="md-layout-item md-small-size-100">
                    <md-field  :class="getValidationClass('userType')" class="field-margin">
                      <label>User type</label>
                      <md-select
                        name="user-type"
                        v-model="model.userType"
                        placeholder="User Type"
                        class="input input-width"
                      >
                      <div class="account-type-dropdown">
                        <md-option
                          v-for="role in roles"
                          :key="'role-' + role.id"
                          :value="role.id"
                        >{{ role.display_name }}</md-option
                        >
                      </div>
                      </md-select>
                      <span class="md-error" v-if="userTypeValidationError">{{
                          userTypeValidationError
                        }}</span>
                    </md-field>
                  </div>
                </v-col>

                <v-col cols="12">
                  <div
                    class="md-layout-item md-small-size-100 md-layout md-alignment-center-space-between"
                  >
                    <md-checkbox
                      v-model="model.terms"
                      data-cy="terms-and-conditions-checkbox"
                    >
                    <span id="checkboxLabel">
                      I accept the
                        <a @click="openTerms" class="login-link">
                          terms and conditions and the privacy policy
                        </a>
                    </span></md-checkbox>
                    <span class="md-error warning-text" v-if="termsValidationError">{{
                        termsValidationError
                      }}</span>
                  </div>
                </v-col>
              </div>
            </md-card-content>
            <div style="margin-bottom: 26px;">
              <md-button
                @click="save"
                class="wide-button save-btn"
                id="register"
              >Save
                </md-button>
              <md-button
                @click="backToLogin"
                class="wide-button cancel-btn"
              >Cancel
                </md-button>
          </div></div>
          <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>
              <div class="title-color">
                SEAMLESS SOURCE USER TERMS AND CONDITIONS
              </div>
            </md-dialog-title>

            <md-dialog-content class="paragraph-color">
              <p>
                Please read this Agreement carefully before accessing or using the
                Website. By accessing or using any part of the Website, You agree to
                become bound by the terms and conditions of this agreement.
              </p>
              <p>
                If You do not agree to all the terms and conditions of this agreement,
                then You may not access the Website or use any services.
              </p>
              <p>
                If these terms and conditions are considered an offer by Seamless Source,
                acceptance is expressly limited to these terms. The Website is
                available only to individuals who are at least 18 years old.
              </p>
              <p></p>
              <p>
                Seamless Source is not the owner, holder or distributor of any products or
                services created or rendered by the Users of the Platform. Seamless Source
                does not represent either the Service Provider or Customer in specific
                User transactions (“User to User Agreements”).
              </p>
              <p>
                Seamless Source does not control and is not liable to or responsible for the
                quality, safety, lawfulness or availability of the products or
                services offered by Users on the Website or the ability of the Users
                to complete an order, Proposal and/or payments.
              </p>
              <p>
                All content processed through the Seamless Source Website is monitored by us.
                Your personal information may be stored by us for use by third parties
                in line with the GDPR.
              </p>
              <p></p>
              <p>
                <u>Effective date: 16.05.2019</u>
              </p>
              <h3></h3>
              <ol class="ol-style">
                <li>INTRODUCTION</li>
              </ol>
              <p>
                1.1. Seamless Source Ltd is a company registered in the United Kingdom, with
                registration number. 11999422, having registered address at (Seamless Source,
                The Hive, Maudslay Building 50, Shakespeare St, Nottingham, NG1 4FQ,
                UNITED KINGDOM.)
              </p>
              <p>
                1.2. Seamless Source provides an online Platform with url:
                https://seamlesssource.com for fashion business owners to connect with each
                other and manage your related business activities with our platform.
              </p>
              <p>
                1.3. The Seamless Source website facilitates the relationship between fashion
                business owners (users) as follows:
              </p>
              <p>
                1.3.1 to identify and determine the products and services available to
                Users;
              </p>
              <p>1.3.2 to connect users with potential requirements;</p>
              <p>
                1.3.3 to publish and make available users’ catalogues of products and
                services;
              </p>
              <p>
                1.3.4 to facilitate the placing of orders of products or services by
                users; through confirming pricing of products, ensuring that an
                agreement is concluded for the delivery of the products and services
                between the two users (User to User Agreement);
              </p>
              <p>
                1.3.5 to ensure that payment is facilitated between the Users safely
                through holding funds in Seamless Source business account until confirmation
                of completion of the transaction between the two users.
              </p>
              <p>
                1.3.6 making payment on behalf of the user, out of the funds held in
                Seamless Source account, to the Service Provider on the Customer’s
                instruction.
              </p>
              <p></p>
              <h3></h3>
              <ol class="ol-style" start="2">
                <li>DEFINITIONS</li>
              </ol>
              <p>
                2.1. “Confidential Information” means all private, business or
                technical information of Seamless Source and/or the User, whether it is
                received, accessed or viewed by other Users in writing, visually,
                electronically or in any other way. Confidential Information includes,
                without limitation, individual identification and sensitive data,
                intellectual property, technical information, marketing and business
                plans, databases, prices, quotations, quotes, specifications,
                formulations, tooling, prototypes, sketches, models, drawings,
                specifications, procurement requirements, engineering information,
                samples, computer software (source and object codes), forecasts,
                identity of or details about actual or potential Customers or
                projects, techniques, inventions, discoveries, know-how and trade
                secrets. &#8220;Confidential Information&#8221; also includes all such
                business or technical information of any third party that is in the
                possession of Seamless Source or/and User. Notwithstanding the foregoing, any
                information disclosed by the User will be considered Confidential
                Information, only if such information is conspicuously designated as
                &#8220;Confidential&#8221; or &#8220;Proprietary&#8221; or would
                reasonably be regarded as being of a confidential nature, or if
                provided visually, is identified as confidential at the time of
                disclosure, or would reasonably be regarded as being of a confidential
                nature;
              </p>
              <p>
                2.2 “Customer” means any registered User using the Website, to seek
                and receive products or services offered by the other user on the
                basis of design or idea/vision of a product/service;
              </p>
              <p>
                2.3 “GDPR” means the General Data Protection Regulation 2016/679 as
                amended;
              </p>
              <p>
                2.4 “Intellectual Property Rights” means including without limitation,
                information relating to the pricing of the products and services
                provided to the Customer under this Agreement, the Service Providers’
                products and services, operations, prospects, know-how, design rights,
                trade secrets, raw data information, client information, market
                opportunities and/or business affairs and any other information which
                ought reasonably to be regarded as confidential irrespective of how
                they were marked or provided;
              </p>
              <p>
                2.5 “Service Provider” means registered User using the Website to
                offer, discuss, render and sell free or paid services and products to
                Customers and other Users;
              </p>
              <p>
                2.6 “Payment Method” means payment made with a valid payment card
                issued by a bank (Credit/Debit card), or other financial institution,
                who is authorised to issue payment cards as acceptable to Seamless Source, or
                any other method of payment which Seamless Source may accept from time to
                time in its sole discretion;
              </p>
              <p>
                2.7 “Platform” means www.seamlesssource.com / https://seamlesssource.com and/or
                any of its sub-domains or syntax analogues and the tools and functions
                provided by the Website to manage information or interact with other
                Users of the Website, according to these Terms and Conditions,
                Additional agreements, their changes, updates and amendments;
              </p>
              <p>
                2.8 “Service Fee” means the payment due to Seamless Source by a User / You
                for use of the Platform according to these Terms and Conditions,
                Additional Agreements, or their changes, amendments or updates. These
                fees may include but are not limited to Commission Fees, Service Fees
                or other fees applicable. All Service fee payments are
                <strong>
                  NON-REFUNDABLE and due immediately on sign-on irrespective of how
                  the deal proceeds or if a dispute arises between the two users </strong
                >;
              </p>
              <p>
                2.9 “Seamless Source Payment System” means the Seamless Source designated payment
                system provided by third party provider, such as but not limited to
                “TransferWise”, “Stripe”, “PayPal”, “Payoneer”, connected to the
                Platform, and used by Users to make payments in accordance with the
                “User to User Agreements” and to pay Seamless Source Service and Commission
                Fees.
              </p>
              <p>
                2.10 “User”, “You”, “Your” means any person, individual or legal
                entity, visiting and using the Website, Platform, their services
                and/or any of their subdomains;
              </p>
              <p>
                2.11 “User to User Agreement” means an agreement between two users on
                price, performance, provision and receipt of product and/or services;
              </p>
              <p>
                2.12 “User Content” means any information, text, images, video, visual
                data, posts, comments, revisions, or other information that Users
                provide, post, send, submit, display, distribute, disclose or any
                other way use and display on the Platform or/and Website;
              </p>
              <p>
                2.13 “Website” means www.seamlesssource.com / https://seamlesssource.com and/or
                any of its sub-domains or syntax analogues;
              </p>
              <p></p>
              <h3></h3>
              <ol class="ol-style" start="3">
                <li>
                  <strong>TERMS OF USE.</strong>
                </li>
              </ol>
              <p>
                3.1 The following terms and conditions govern all use of the Seamless Source
                website and all content, services and products available at or through
                the website (taken together, the Website). The Website is owned and
                operated by Seamless Source;
              </p>
              <p>
                3.2 The Website is offered subject to Your acceptance without
                modification of any of the terms and conditions contained herein and
                all other operating rules, policies (including, without limitation,
                Seamless Source&#8217;s Privacy Policy) and procedures that may be published
                from time to time on this website by Seamless Source (collectively, the
                &#8220;Agreement&#8221;).
              </p>
              <p></p>
              <h3></h3>
              <ol class="ol-style" start="4">
                <li>
                  <strong>
                    USERS &#8211; YOUR SEAMLESS SOURCE ACCOUNT AND PLATFORM /THE WEBSITE.
                  </strong>
                </li>
              </ol>
              <p>
                4.1 The Seamless Source Platform is an online web-based tool for Users
                (individuals, legal entities, brand owners or fashion freelance
                specialists) to provide their services, a means of communication and
                project management, in order to fulfil any stage of creation of
                products and/or services, starting from an idea or visualization to
                manufacturing of the final product;
              </p>
              <p>
                4.2 Users may search for the appropriate services of Manufacturing or
                services offering; post design projects (any development stage – from
                idea to completely crafted design); discuss and accept Service
                Providers’ offers and services; discuss and accept the “User to User
                Agreement” terms with the service provider and make payments via the
                Platform in accordance with the “User to User Agreement”;
              </p>
              <p>
                4.3 Service Providers may post information on services provided as
                well as production catalogues; view and discuss the orders with
                Customers; offer services to Customers; discuss and accept the “User
                to User Agreement” terms with the Customer and be paid according to
                the “User to User Agreement”;
              </p>
              <p>
                4.4 If You create an account on the Website, You are responsible for
                maintaining the security of Your account, and You are fully
                responsible for all activities that occur under the account and any
                other actions taken in connection with the account;
              </p>
              <p>
                4.5 A User Account created by You shall be confirmed only once
                documentation verifying your identity (as required by Seamless Source from
                time to time) is provided, which may include any documentation
                reasonably required by Seamless Source including images, video calls, credit
                references, bank statements, company information and the like;
              </p>
              <p>
                4.6 You may not share a User Account with other persons or allow
                multiple/various Users to use Your User Account. You hereby agree that
                Seamless Source shall not be liable for any direct, indirect, punitive,
                incidental or consequential, actual, special, damages or any damages
                whatsoever arising from the Your failure to comply with this clause;
              </p>
              <p>
                4.7 You have to notify Seamless Source immediately of any unauthorized use of
                Your User Account, Login or Password or any other violation of Your
                security;
              </p>
              <p>
                4.8 You must not act in a misleading or unlawful manner, including in
                a manner intended to trade on the name or reputation of others, and
                Seamless Source may change or remove any description or keyword that it
                considers inappropriate or unlawful, or otherwise likely to cause
                Seamless Source liability;
              </p>
              <p>
                4.9 Seamless Source will not be liable for any acts or omissions by You,
                including any damages of any kind incurred because of such acts or
                omissions;
              </p>
              <p>
                4.10 Customers and Service Providers bear the risk of conducting
                adequate due diligence reviews on each other prior to entering into a
                User to User Agreement and Seamless Source accepts no responsibility for any
                contractual disputes or breaches which may result from User to User
                Agreements. This will include wrong orders, bad quality or anything
                else;
              </p>
              <p>
                4.11 You hereby confirm that You are duly warned and informed that You
                may be at risk of dealing with persons acting in bad faith, malicious
                intense, fallacy, delusion, or fraud. Seamless Source cannot and is not
                liable or responsible for confirming and validating each User’s
                identity, for lawfulness, accuracy, correctness, validity,
                reliability, and or completeness of any information provided by Users;
              </p>
              <p>
                4.12 Customers and Service Providers may not engage in ANY TYPE OF
                COMMUNICATION OUTSIDE OF the Platform with each other. This includes
                but is not limited to e-mail, telephone, text messages, Whatsapp or
                any other means of communication. Any such direct communications will
                constitute a material breach of these terms allowing Seamless Source to
                cancel these terms, terminate their account and/or refuse them as
                users in future at its sole discretion. You are encouraged to advise
                Seamless Source immediately if the other party encourages such
                communications. Where Users elect to proceed with their transactions
                outside of the Seamless Source Platform nonetheless, Users will still remain
                liable for all fees due in terms of clause 7.3.
              </p>
              <p></p>
              <ol class="ol-style" start="5">
                <li>
                  <strong>
                    SERVICE PROVIDERS &#8211; RESPONSIBILITY OF CONTRIBUTORS.
                  </strong>
                </li>
              </ol>
              <p>
                5.1 If You post material to the Website, post links on the Website, or
                otherwise make (or allow any third party to make) material available
                by means of the Website (Content), You are entirely responsible for
                such Content and any harm resulting from that Content, regardless of
                whether the Content in question constitutes text, graphics, an audio
                file, or computer software;
              </p>
              <p>5.2 By making Content available, You represent and warrant that:</p>
              <p>
                5.2.1 the downloading, copying and use of the Content will not
                infringe the proprietary rights, including but not limited to the
                copyright, patent, trademark or trade secret rights, of any third
                party;
              </p>
              <p>
                5.2.2 if a third party has rights to intellectual property You make
                available by means of the Website, You must have:
              </p>
              <p>
                5.2.2.1 received permission from such third party to post or make
                available the Content, (including but not limited to any software), or
              </p>
              <p>
                5.2.2.2 secured from such third party a waiver as to all rights in or
                to the Content;
              </p>
              <p>
                5.2.2.3 fully complied with any third-party licenses relating to the
                Content;
              </p>
              <p>
                5.2.2.4 done all things necessary to successfully pass through to end
                users any required terms.
              </p>
              <p></p>
              <p>5.3 You shall also ensure that:</p>
              <p>
                5.3.1 the Content does not contain or install any viruses, worms,
                malware, Trojan horses or other harmful or destructive content;
              </p>
              <p>
                5.3.2 the Content is not spam, is not machine- or randomly-generated,
                and does not contain unethical or unwanted commercial content designed
                to drive traffic to third party sites or boost the search engine
                rankings of third-party sites, or to further unlawful acts (such as
                phishing) or mislead recipients as to the source of the material (such
                as spoofing);
              </p>
              <p>
                5.3.3 the Content is not pornographic, does not contain threats or
                incite violence towards individuals or entities, and does not violate
                the privacy or publicity rights of any third party;
              </p>
              <p>
                5.3.4 the Content does not mislead anyone into thinking that You are
                another person or company.
              </p>
              <p>
                5.4 By submitting Content to Seamless Source for inclusion in its website,
                You grant Seamless Source a world-wide, royalty-free, and non-exclusive
                license to reproduce, modify, adapt and publish the Content solely for
                the purpose of displaying, distributing and promoting Your services;
              </p>
              <p>
                5.5 Without limiting any of those representations or warranties,
                Seamless Source has the right (though not the obligation) to, in
                Seamless Source&#8217;s sole discretion (i) refuse or remove any content
                that, in Seamless Source&#8217;s reasonable opinion, violates any Seamless Source
                policy or is in any way harmful or objectionable, or (ii) terminate or
                deny access to use of the Website to any individual or entity for any
                reason, in Seamless Source&#8217;s sole discretion. Seamless Source will refund a
                Customer in line with its refund rights (less any amounts owing to
                Seamless Source), within 10 (ten) working days of receipt of such request.
              </p>
              <p>
                5.6 Seamless Source shall conduct verification and due refence checks on You
                periodically. These checks may be made by requesting You to provide
                documentation or conduct interviews with Seamless Source or by monitoring
                your ratings on the website by Customers. If you do not cooperate with
                any requests which are required to perform this verification, your
                User account may be blocked by Seamless Source.
              </p>
              <p>
                5.7 Seamless Source shall have the sole discretion to hold back payment to
                Service Providers for up to 30 days where such Service Providers have
                not been rated favourably, have no rating yet, all first time
                transactions on the Platform and/or where the order is of a large
                quantity (orders above £2,000.00), until Customers have confirmed that
                they are satisfied with the quality of the order, which the Service
                Provider accepts.
              </p>
              <p></p>
              <ol class="ol-style" start="6">
                <li>PAYMENT</li>
              </ol>
              <p>6.1 General Terms</p>
              <p>
                By selecting a product or service, You agree to pay Seamless Source the
                Service Fees indicated (additional payment terms may be included in
                other/further communications).
              </p>
              <p></p>
              <p>6.2 Payment Methods</p>
              <p>
                6.2.1
                <u>Card Payments</u>:
              </p>
              <p>
                6.2.1.1 You shall be required to make payment via a valid payment
                (Credit/Debit) card, issued by a bank, or other financial institution,
                who is authorised to issue payment cards and is acceptable to
                Seamless Source,
              </p>
              <p>
                6.2.1.2 You may also make use of the Seamless Source Payment System as
                designated by Seamless Source from time to time; or
              </p>
              <p>
                6.2.1.3 any other method of payment, may be accepted by Seamless Source in
                writing from time to time in its sole discretion;
              </p>
              <p>6.2.1.4 Payments are not refundable.</p>
              <p>6.3 Payments by Customers and to Service Providers</p>
              <p>
                6.3.1 Service Providers shall ensure that quotations for services are
                as near accurate as possible when included in the User to User
                Agreement;
              </p>
              <p>
                6.3.2 The Customer is to pay the sum quoted in the Proposal Agreement,
                in accordance with the Seamless Source Payment Methods set out in clause 6;
              </p>
              <p>
                6.3.3 Seamless Source will not be held accountable to Users for any delays
                and similarly will not apply any discounts to payment to Service
                Providers for such delays or defects of any nature in orders;
              </p>
              <p>
                6.3.4 Service Providers shall not begin to render any services until
                it is confirmed that the full amount required from a Customer has been
                paid into the Seamless Source account;
              </p>
              <p>
                6.3.5 A Service Provider should ensure that it grants reasonable
                rights of return and warranty in line with current laws in the
                territory of the United Kingdom and accepted trade practice in the
                industry, taking into account the unique nature of the orders however.
              </p>
              <p>
                6.3.6 Where it becomes apparent that an adjustment in the initial
                quotation for services is required, such adjustment will be made by
                <strong>
                  notifying Seamless Source thereof and where there is a shortfall in the
                  amount paid by the Customer, the Customer shall immediately pay such
                  additional amount into the Seamless Source account </strong
                >;
              </p>
              <p>
                6.3.7 Once an Invoice is raised and paid by the Customer via the
                Seamless Source Platform, Seamless Source is eligible to deduct its fee from the
                amount and will, within 5 (five) banking days.
              </p>
              <p>
                6.3.8 The balance remaining in the Seamless Source account shall be paid to
                the Service Provider within 10 (ten) banking days from the day a
                Customer has authorised the payment on the Seamless Source system.
              </p>
              <p>
                6.3.9
                <strong>
                  In the event that the Service Provider requests payment and the
                  Customer is advised by Seamless Source of such request, but ignores and/or
                  omits to give an instruction of payment to Seamless Source or otherwise
                  within 7 (seven) days of such request, Seamless Source shall have the right
                  to release payment to the value of the Service Provider’s request to
                  the Service Provider, without any liability to the Customer.
                </strong>
              </p>
              <p></p>
              <ol class="ol-style" start="7">
                <li>FEES</li>
              </ol>
              <p>
                In consideration of the Platform services provided by Seamless Source, Users
                shall pay Seamless Source Service fees and Commissions (as the case
                requires), according to the rates and terms listed in herein and or as
                in Additional Agreement:
              </p>
              <p></p>
              <p>7.1 Service Fees:</p>
              <p>
                7.1.1 Service fees shall be charged at the rate quoted on sign-up;
              </p>
              <p>
                7.1.2 Seamless Source reserves the right to change the terms related to the
                rates and application of Service Fees at any time.
              </p>
              <p>
                7.1.3 All Service fees payments are NON-REFUNDABLE and will be a first
                charge against any monies held in Seamless Source account.
              </p>
              <p>
                7.1.4 Service fees shall include all state, and/or other governmental
                sales, goods and services, value-added, harmonized or other taxes,
                fees or charges applied to Seamless Source.
              </p>
              <p></p>
              <p>
                7.2 Commissions
                <br />In addition to Service fees, Service Providers may be required
                to pay Seamless Source a Commission fee, according to terms and conditions of
                the “Additional Agreement for Service Providers”.
              </p>
              <p>
                7.3 By using the Platform and the Website, You acknowledge and
                warrant, that in the case
                <strong>
                  where an amount received to the Seamless Source Payment System is less than
                  the Total Net price defined in the Invoice or “User to User
                  Agreement” (Proposal, accepted by the Customer), or in case of
                  unilateral or bilateral violation of payment terms, defined in the
                  Terms and Conditions by You and/or the Service Provider, You are
                  responsible and liable for paying any shortfall </strong
                >.
              </p>
              <p></p>
              <ol class="ol-style" start="8">
                <li>
                  <strong>DISPUTES BETWEEN USERS.</strong>
                </li>
              </ol>
              <p>
                8.1 If requested by either the Customer or the Service Provider,
                Seamless Source may exercise its discretion in assisting and consulting with
                Users in resolving any disputes between Users where both Users agree
                to involve Seamless Source in the dispute resolution process. Any involvement
                by Seamless Source is purely in an attempt to assist Parties.
              </p>
              <p>
                8.2 On notification of a dispute, Seamless Source may request Users involved
                in the dispute to provide supporting documents. Seamless Source will not be
                in any way be liable for documentation which is not authentic,
                accurate, up to date, complete and lawful.
              </p>
              <p>
                8.3 The Users will be required to acknowledge their understanding and
                agreement, that Seamless Source is not a judicial or arbitration institution
                and will provide assistance only in an effort to facilitate resolution
                of the dispute. Its determinations will not be binding on the parties.
              </p>
              <p>
                8.4 If You involve Seamless Source to settle any dispute with another User,
                Seamless Source is not and will not be liable for any special, direct,
                indirect, exemplary, punitive, incidental or consequential damages or
                any damages whatsoever, including, but not limited to loss of profit,
                which may arise in connection with any opinion, decision, assistance
                etc, given to You by Seamless Source.
              </p>
              <p>
                8.5 No information or advice provided by Seamless Source and its
                representatives, including, without limitation Seamless Source’s directors,
                members of the board, shareholders, officers, employees, agents,
                authorised persons and (sub) contractors, whether directly or
                indirectly, will constitute personal, legal or financial advice or
                create a warranty, condition or representation of any kind with
                respect to this Website or the Platform or its services found at this
                Website. You should consult an appropriate professional advisor.
              </p>
              <p></p>
              <ol class="ol-style" start="9">
                <li>
                  <strong>COPYRIGHT INFRINGEMENT AND DMCA POLICY.</strong>
                </li>
              </ol>
              <p>
                9.1 As Seamless Source asks others to respect its intellectual property
                rights, it respects the intellectual property rights of others. If You
                believe that material located on or linked to by Seamless Source violates
                Your copyright, You are encouraged to notify Seamless Source in accordance
                with Seamless Source&#8217;s Digital Millennium Copyright Act
                (&#8220;DMCA&#8221;) Policy. Seamless Source will respond to all such
                notices, including as required or appropriate by removing the
                infringing material or disabling all links to the infringing material.
              </p>
              <p>
                9.2 Seamless Source will terminate a User&#8217;s access to and use of the
                Website if, under appropriate circumstances, the User is determined to
                be a repeat infringer of the copyrights or other intellectual property
                rights of Seamless Source or others in Seamless Source’s sole discretion.
              </p>
              <p>
                9.3 In the case of such termination, Seamless Source will have no obligation
                to provide a refund of any amounts previously paid to Seamless Source.
              </p>
              <p></p>
              <ol class="ol-style-long" start="10">
                <li>
                  <strong>INTELLECTUAL PROPERTY.</strong>
                </li>
              </ol>
              <p>
                This Agreement does not transfer from Seamless Source to You any Seamless Source or
                third-party intellectual property, and all right, title and interest
                in and to such property will remain (as between the parties) solely
                with Seamless Source. The Seamless Source logo, and all other trademarks, service
                marks, graphics and logos used in connection with Seamless Source, or the
                website are trademarks or registered trademarks of Seamless Source or
                Seamless Source&#8217;s licensors. Other trademarks, service marks, graphics
                and logos used in connection with the website may be the trademarks of
                other third parties. Your use of the website grants You no right or
                license to reproduce or otherwise use any Seamless Source or third-party
                trademarks.
              </p>
              <p></p>
              <ol class="ol-style-long" start="11">
                <li>AMENDMENTS</li>
              </ol>
              <p>
                11.1 Seamless Source reserves the right, at its sole discretion, to modify or
                replace any part of this Agreement.
                <strong>
                  It is Your responsibility to check this Agreement periodically for
                  changes </strong
                >. Your continued use of or access to the Website following the
                posting of any changes to this Agreement constitutes acceptance of
                those changes.
              </p>
              <p>
                11.2 Seamless Source may also, in the future, offer new services and/or
                features through the Website (including, the release of new tools and
                resources). Such new features and/or services shall be subject to the
                terms and conditions of this Agreement.
              </p>
              <p></p>
              <ol class="ol-style-long" start="12">
                <li>TERMINATION</li>
              </ol>
              <p>
                12.1 Seamless Source may terminate Your access to all or any part of the
                Website at any time, with or without cause, with or without notice,
                effective immediately. If You wish to terminate this Agreement or Your
                Seamless Source account (if You have one), You may simply discontinue using
                the Website.
              </p>
              <p>
                12.2 Notwithstanding the above, if You have entered into a User to
                User Agreement, such account will only be terminated by Seamless Source if
                You have paid the fees in terms of this agreement.
              </p>
              <p>
                12.3 If you terminate the User to User agreement for any reason,
                <strong>
                  Your obligations in terms of this agreement shall not automatically
                  be discharged. You will still be liable to pay Seamless Source’s Service
                  and other Fees
                </strong>
                depending on the reasons for the termination of the User to User
                Agreement. You shall therefore notify Seamless Source in writing of such
                termination, setting out the reasons therefore.
              </p>
              <p>
                12.4 All provisions of this Agreement which by their nature should
                survive termination shall survive termination, including, without
                limitation, ownership provisions, warranty disclaimers, indemnity and
                limitations of liability.
              </p>
              <ol class="ol-style-long" start="13">
                <li>
                  <strong>DISCLAIMER OF WARRANTIES.</strong>
                </li>
              </ol>
              <p>
                13.1 Seamless Source is not the owner, holder or distributor of any products
                or services created or rendered by the Users of the Platform. Seamless Source
                does not represent either the Service Provider or Customer in specific
                Users transactions (“User to User Agreements”).
              </p>
              <p>
                13.2 Seamless Source does not control and is not liable to or responsible for
                the quality, safety, lawfulness or availability of the products or
                services offered by Users on the Website or the ability of the Users
                to complete an order, Services, Proposal and/or payments.
              </p>
              <p>
                13.3 The Website is provided &#8220;as is&#8221;. Seamless Source and its
                suppliers and licensors hereby disclaim all warranties of any kind,
                express or implied, including, without limitation, the warranties of
                merchantability, fitness for a particular purpose and
                non-infringement.
              </p>
              <p>
                13.4 Neither Seamless Source nor its suppliers and licensors, makes any
                warranty that the Website will be error free or that access thereto
                will be continuous or uninterrupted.
              </p>
              <p>
                13.5 You understand that You download from, or otherwise obtain
                content or transact through, the Website at Your own discretion and
                risk.
              </p>
              <p></p>
              <ol class="ol-style-long" start="14">
                <li>
                  <strong>LIMITATION OF LIABILITY.</strong>
                </li>
              </ol>
              <p>
                14.1 Seamless Source shall not be held responsible for any breaches of User
                to User Agreements, including, but not limited with unsatisfactory
                quality, defects, delays, wrong calculations, violation of warranty,
                and any violation of copyright, intellectual property etc.
              </p>
              <p>
                14.2 Seamless Source does not introduce Users to each other and does not
                participate in concluding User to User Agreements. Users select to
                transact with each other without any intervention, inducement or
                encouragement from Seamless Source and do so at their own risk;
              </p>
              <p>
                14.3 Seamless Source in its sole discretion may develop and provide Website
                and Platform updates, upgrades, bug fixes or patches or error
                corrections. Such changes may result in modification or deletion of
                certain features, data or functionality. With any of such changes and
                updates Seamless Source does not guarantee to maintain, keep or update
                particular functions or functionality of the Website and the Platform.
                Seamless Source reserves the right to update, remove, suspend of discontinue
                provision of its services and provision of availability to the Website
                without notice. Seamless Source will not be held liable for any of such cases
                and their results.
              </p>
              <p>
                14.4 In case of technical glitches, disruption of Platform service or
                any other cases where normal use of the Platform is not possible, for
                whatever reason,
                <strong>
                  Seamless Source is not and will not be liable for any losses of whatsoever
                  nature suffered by a User as a result of Platform service
                  disruption.
                </strong>
              </p>
              <p>
                14.5 In no event will Seamless Source, or its suppliers or licensors, be
                liable with respect to any subject matter of this agreement under any
                contract, negligence, strict liability or other legal or equitable
                theory for:
              </p>
              <p>14.5.1 any special, incidental or consequential damages;</p>
              <p>
                14.5.2 the cost of procurement for substitute products or services;
              </p>
              <p>14.5.3 for interruption of use or loss or corruption of data; or</p>
              <p>
                14.5.4 for any amounts that exceed the fees paid by You to Seamless Source
                under this agreement prior to the cause of action.
              </p>
              <p>
                14.5.5 Seamless Source shall have no liability for any failure or delay due
                to matters beyond their reasonable control.
              </p>
              <p>
                14.6 The above shall not apply to the extent prohibited by applicable
                law.
              </p>
              <p></p>
              <ol class="ol-style-long" start="15">
                <li>
                  <strong>GENERAL REPRESENTATION AND WARRANTY.</strong>
                </li>
              </ol>
              <p>15.1 You represent and warrant that:</p>
              <p>
                15.1.1 Your use of the Website will be in strict accordance with the
                Seamless Source Privacy Policy, with this Agreement and with all applicable
                laws and regulations (including without limitation any local laws or
                regulations in Your country, state, city, or other governmental area,
                regarding online conduct and acceptable content, and including all
                applicable laws regarding the transmission of technical data exported
                from the country in which You reside) and
              </p>
              <p>
                15.1.2 Your use of the Website will not infringe or misappropriate the
                intellectual property rights of any third party.
              </p>
              <p></p>
              <ol class="ol-style-long" start="16">
                <li>INDEMNIFICATION</li>
              </ol>
              <p>
                You agree to indemnify and hold harmless Seamless Source, its contractors,
                and its licensors, and their respective directors, officers, employees
                and agents from and against any and all claims and expenses, including
                attorneys&#8217; fees, arising out of Your use of the Website,
                including but not limited to Your violation of this Agreement.
              </p>
              <p></p>
              <ol class="ol-style-long" start="17">
                <li>MISCELLANEOUS</li>
              </ol>
              <p>
                17.1 This Agreement constitutes the entire agreement between Seamless Source
                and You concerning the subject matter hereof and may only be modified
                by a written amendment signed by an authorised executive of Seamless Source,
                or by the posting by Seamless Source of a revised version;
              </p>
              <p>
                17.2 Except to the extent applicable, if any law provides otherwise,
                this Agreement, any access to or use of the Website will be governed
                by the laws of England and Wales, excluding its conflict of law
                provisions;
              </p>
              <p>
                17.3 The proper venue for any disputes arising out of or relating to
                any of the same will be the state and federal courts located in
                Nottingham, UK;
              </p>
              <p>
                17.4 Except for claims for injunctive or equitable relief or claims
                regarding intellectual property rights (which may be brought in any
                competent court without the posting of a bond), any dispute arising
                under this Agreement shall be finally settled in accordance with the
                Comprehensive Arbitration Rules of the Judicial Arbitration and
                Mediation Service, Inc. (&#8220;JAMS&#8221;) by three arbitrators
                appointed in accordance with such Rules.
              </p>
              <p>
                17.5 The arbitration shall take place in Nottingham, UK, in the
                English language and the arbitral decision may be enforced in any
                court.
              </p>
              <p>
                17.6 The prevailing party in any action or proceeding to enforce this
                Agreement shall be entitled to costs and attorneys&#8217; fees.
              </p>
              <p>
                17.7 If any part of this Agreement is held invalid or unenforceable,
                that part will be construed to reflect the parties&#8217; original
                intent, and the remaining portions will remain in full force and
                effect.
              </p>
              <p>
                17.8 A waiver by either party of any term or condition of this
                Agreement or any breach thereof, in any one instance, will not waive
                such term or condition or any subsequent breach thereof;
              </p>
              <p>
                17.9 You may not assign Your rights under this Agreement to any party.
                Seamless Source may assign its rights under this Agreement without condition;
              </p>
              <p>
                17.10 All content processed through the Seamless Source Website is monitored
                by us. Your personal information may be stored by us for use by third
                parties. Seamless Source is a data controller for purposes of the GDPR.
              </p>
              <p>
                17.11 Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timeliness, performance, completeness or
                suitability of the information and materials found or offered on this
                website for any purpose.
              </p>
              <p>
                17.12 You acknowledge that such information and materials may contain
                inaccuracies or errors and we expressly exclude liability for any such
                inaccuracies or errors to the fullest extent permitted by law.
              </p>
              <p>
                17.13 Your use of any information or materials on this website is
                entirely at your own risk, for which we shall not be liable. It shall
                be your own responsibility to ensure that any products, services or
                information available through this website meet your specific
                requirements.
              </p>
              <p>
                17.14 Seamless Source protects the privacy of Users’ personal data and
                information. You are required to read Seamless Source Privacy Policy and
                Cookies Policy, which sets out Seamless Source’s policies and procedures
                regarding the collection, use and disclosure of personal information
                received from Users. By using this Website and the Platform, You
                unconditionally agree to the processing, regulations and rules
                described therein and You warrant that all data provided by You is
                true, complete, accurate and up-to- date. You are responsible for
                maintaining and promptly updating User Account and User Profile
                information;
              </p>
              <p>
                17.15 This Agreement will be binding upon and will inure to the
                benefit of the parties, their successors and permitted assigns.
              </p>
              <p></p>
              <h3>
                <strong>ADDITIONAL AGREEMENT FOR MANUFACTURERS AND SUPPLIERS</strong>
              </h3>
              <p>
                <u>Effective date: 16.05.2019</u>
              </p>
              <ol class="ol-style">
                <li>
                  When You are acting/using the Platform as Manufacturer or Supplier,
                  additionally to terms, conditions and provisions set forth in Terms
                  and Conditions (hereinafter referred to as – Terms and Conditions),
                  there are certain rules set forth in this Additional Agreement that
                  You need to accept, respect and follow.
                </li>
                <li>
                  In case of any conflict or inconsistency between Terms and
                  Conditions and this Additional Agreement, the Terms and Conditions
                  will prevail. If this Additional Agreement does not specify
                  otherwise, it is subjected to all provisions defined in Terms and
                  Conditions.
                </li>
                <li>
                  The Definitions, used in this Additional Agreement are set forth in
                  Terms and Conditions.
                </li>
                <li>
                  In addition to Your obligations, authorizations, and warranties set
                  forth in Terms and Conditions, other Additional Agreements and their
                  amendments, changes and updates, as Manufacturer, You confirm,
                  acknowledge, represent, agree and warrant that YOU SHOULD AND WILL
                  comply with and fulfil all the provisions, defined in this
                  ADDITIONAL AGREEMENT.
                </li>
                <li>
                  As it is set forth in Terms and Conditions (“USER TO USER
                  AGREEMENTS”):
                </li>
              </ol>
              <p>
                5.1. The Platform provides Users with an utility for conclusion of
                simple “User to User Agreement” (hereinafter referred to as &#8211;
                “General User to User Agreement”), which consists of (1) Manufacturers
                (hereinafter within this Additional Agreement referred to also as
                -You, Your) Proposal on the price of services and products, ordered to
                the Customer, payment deadlines and simple delivery terms, proof of
                completion You are going to present upon presenting the result of the
                rendered service or produced product and (2) Customers’ Accept to such
                Proposal. Users are strongly recommended, additionally to “General
                User to User Agreement”, to enter into agreements on terms and
                conditions of performance of services and/or manufacturing of the
                products, delivery terms, return rights, confidentiality,
                non-disclosure, warranty terms, intellectual property rights etc.)
                (Hereinafter &#8211; referred to as “Additional User to User
                Agreements”, but together “General User to User Agreement” and
                Additional User to User Agreement”- “User to User Agreement”) to avoid
                any possible disputes concerning the performance of Users general
                obligations according to “General User to User Agreement”.
              </p>
              <p>
                5.2. Price per unit, Price excluding VAT (hereinafter referred to as –
                Total Net Price), VAT rate and the amount and Total Price including
                VAT (hereinafter referred to as – Total Gross Price) should be
                precisely defined in Your Proposal/ “User to User Agreement”. Total
                Net Price includes all the taxes and fees (except VAT, which must be
                indicated separately, as set forth in Article 5.3.), applicable to You
                and Your services and products, as well as include all additional
                expenses, including, but not limited to transportation, delivery, work
                and material expenses, warranty service expenses and other expenses,
                related to proper and complete execution of the Customers order and
                “User to User Agreements”.
              </p>
              <p>
                5.3. The following provisions are obligatory to any “User to User
                Agreement”:
              </p>
              <p>1) VAT needs to be indicated;</p>
              <p></p>
              <p>
                2) In case, if Customer is a Consumer, that is an individual person-
                who purchases goods and services for personal use, subjected to
                Consumer protection law- all products are subject to a 14 day returns
                period;
              </p>
              <p>
                3) All the payments according to “User to User Agreements” must be
                settled via Seamless Source Payment System.
              </p>
              <p>
                5.4. As Manufacturer, You must not claim any Intellectual Property
                Rights for or considering the product or service, provided by You on
                the basis of “User to User Agreement”, unless the opposite is agreed
                separately in written in such a “User to User Agreement”. Your work
                fulfilled and/or service provided and/or the product manufactured on
                the basis of Customers order and “User to User Agreement” shall become
                the property of the Customer, who is irrevocably considered to be the
                Author of the work, service and/or product. You should not use,
                display, transmit, distribute, reproduce, publish, duplicate, adapt,
                modify, translate, and otherwise violate Customers’ Intellectual
                Property Rights on such service and/or work result and/or product. You
                retain no rights to use, and will not challenge the validity of
                Customers ownership in such Intellectual Property Rights. You hereby
                waive any moral and financial rights to such property, the disclosure
                and withdrawal of his rights under applicable law to the result of the
                rendered service or produced product.
              </p>
              <p>
                5.5. As a Manufacturer, you understand and confirm that Seamless Source may
                hold Customer funds paid into the Seamless Source payment system and not
                release them to you immediately in the following cases:
              </p>
              <p>
                5.5.1. Active disputes with a Customer or Customers in regards to
                unsatisfactory quality, defects, delays, wrong calculations, violation
                of warranty, and any violation of copyright, intellectual property
                etc. In such cases Seamless Source may hold the funds for up to 30 days from
                the date of payment received or until the dispute is not mutually
                resolved.
              </p>
              <p>
                5.5.2. In cases where a Customer placed a first-time order for
                services with you as a Manufacturer for a total amount exceeding
                £120,- but prior did receive a sample/prototype physically from you to
                verify and confirm the quality and make of the service. In such cases
                Seamless Source may hold the funds for up to 30 days from the date of payment
                received or until the Customer received the product of your services
                and confirmed the services rendered are satisfactory.
              </p>
              <p>
                5.5.3. In cases where your Manufacturer profile rating is absent or is
                less than a mark of 3 (out of 5). In such cases Seamless Source may hold the
                funds for up to 30 days from the date of payment received or until the
                Customer received the product of your services and confirmed the
                services rendered are satisfactory.
              </p>
              <p>
                5.5.4. In cases where Seamless Source holds funds due to reasons mentioned in
                5.5.1.-5.5.3. cannot serve as a reason for you as a Manufacturer not
                to render the ordered services to the Customers that entered into a
                “User to User Agreement” with you.
              </p>
              <ol class="ol-style" start="6">
                <li>
                  You irrevocably, perpetually and unconditionally agree and authorize
                  Seamless Source to receive the payments for Your services and products from
                  the Customers in/via Seamless Source Payment System and Seamless Source may charge
                  applicable Service and Commission fees, set forth in this Additional
                  Agreement, Terms and Conditions, Additional agreements, their
                  amendments, updates and changes, and other debts, and amounts You
                  owe to Seamless Source, from any payment amount, You transfer or receive
                  via Seamless Source Payment System.
                </li>
                <li>
                  As Manufacturer, You confirm and agree to pay applicable Seamless Source
                  Commission fees, according to the rates and terms listed in this
                  Additional agreement in cases such Commission fees apply.
                  <strong>
                    The current Commission Fees for Manufacturers is visible at system
                    when you enter your cost.
                  </strong>
                </li>
              </ol>
              <p>
                7.1. You irrevocably, perpetually and unconditionally agree and
                authorize Seamless Source to charge applicable Service and Commission fees
                from any payment amount, Customer transfers to You via Seamless Source
                Payment System. Irresistibly from this authorization, You are
                responsible and liable for the full and timely payment of Service,
                Commission fees or any other debt or amount You owe to Seamless Source.
              </p>
              <p>
                7.2. In any case, excluding if You prove the contrary by submitting
                the Seamless Source legal, objective and written evidence, Seamless Source will
                consider that the final Total Net price of the “User to User
                Agreement” viewed or accepted by the customer is the largest amount
                from the generated Invoice – The Total Net price of “User to User
                Agreement” or the amount (excluding VAT proportionally to the VAT rate
                in Your Proposal), Customer has paid to You via the Platform and/or
                Seamless Source Payment System.
              </p>
              <ol class="ol-style" start="8">
                <li>
                  You understand and confirm, that by authorizing Seamless Source to receive
                  the payments for Your Services via Seamless Source Payment System, You
                  agree that the payment execution date, is the date, when the payment
                  amount is credited to the Seamless Source Payment System. You warrant that
                  the You will not violate the terms and conditions of Your “User to
                  User Agreement” due to postponed payment transfer to You from
                  Seamless Source Payment System. In case the terms of Your services or
                  delivery terms are subjected to the terms of Customer payment, You
                  confirm, acknowledge, represent, agree and warrant that the terms
                  (deadlines) of services and delivery You have defined in Your
                  Proposal and “User to User Agreement” are harmonized and comply with
                  the actual receive of the payment.
                </li>
                <li>
                  Additional fees may be applied to You, as Manufacturer, according to
                  the appropriate section of Terms and Conditions or other Additional
                  Agreements.
                </li>
                <li>
                  The last changes, amendments and updates to this Additional
                  Agreement are displayed at the beginning of the document as
                  “Effective Date” and Seamless Source retains the right to change or update
                  this Additional Agreement, when required and as Seamless Source sees fit.
                  Seamless Source is not required to inform You of any changes in its
                  Additional Agreement, personally or separately but may do so
                  electronically using the email address You provided during sing up
                  in cases You see fit.
                </li>
              </ol>
              <ol class="ol-style-long" start="11">
                <li>
                  For any designer, brand, buyer or business Seamless Source introduced to
                  You, as manufacturer, you will need to communicate and continue the
                  business via Seamless Source platform. If you, as manufacturer, want to
                  work directly with any of Seamless Source introduced business, you will
                  have to pay Seamless Source one off £ 5,000.00 payment and 10% of service
                  fee for each new order you are manufacturing or suppling for next 5
                  years from the date of customer introduction.
                </li>
                <li>
                  You signify and confirm Your agreement with and understanding of
                  this Additional Agreement, by continue using of the platform.
                </li>
                <li>
                  If You do not agree with this Additional Agreement, You must stop
                  the use of the Website and Platform immediately. Continued use of
                  the Website and Platform shall clearly mean Your acceptance of this
                  Additional Agreement.
                </li>
              </ol>
              <h3>
                <strong>
                  SEAMLESS SOURCE POLICY AND PRONOUNCEMENT ON INTELLECTUAL PROPERTY RIGHT
                </strong>
              </h3>
              <p>
                <u>Effective date: 16.05.2019</u>
              </p>
              <p>
                <u>Seamless Source Ltd</u> (a company registered in the United Kingdom,
                Registration Nr. 11999422, Registered office address: Seamless Source, The
                Hive, Maudslay Building 50, Shakespeare St, Nottingham, NG1 4FQ,
                UNITED KINGDOM).
              </p>
              <p>
                Seamless Source respects and strictly observes all the regulations and legal
                provisions related to the protection of intellectual property rights.
              </p>
              <p>
                Seamless Source is the sole owner or lawful licensee of all the rights and
                interests in the Website, Platform and their services, and content,
                except for User Content, displayed on this Website.
              </p>
              <p>
                Any unauthorized use of Seamless Source Intellectual property, including,
                without limitation, patents, trademarks, service marks, logos,
                designs, or any other branded features without the prior written
                permission of Seamless Source is strictly prohibited. If you violate Seamless Source
                Intellectual property rights, you will be ordered to immediately stop
                such illegal activity and you will be liable to Seamless Source for any and
                all damages which may incur as a result of your violation.
              </p>
              <p>
                Seamless Source declares zero tolerance policy to its Users who infringe the
                Intellectual Property Rights and expects the same of all Users and
                other persons, who use or visit the Website and the Platform
                <a href="http://www.seamlesssource.com" class="link-style">www.seamlesssource.com</a>
              </p>
              <p>
                Any unauthorized copying, publication, reproduction or distribution of
                Third Party Intellectual Property is a violation of the Intellectual
                property right owners&#8217; rights and is therefore prohibited and is
                the subject to penalties, preventive and punitive actions against
                Users-offenders, according to Terms and Conditions of use of the
                Platform and Website.
              </p>
              <p>
                Still Seamless Source does not represent or warrant that the User Content,
                User offers, proposals, User services and production offered or
                displayed, and any and all Users’ materials, data, images, video,
                projects and other information submitted, send or posted on or via the
                Website or Platform does not violate any third-party rights, including
                Intellectual property rights, and Seamless Source makes no representations or
                warranties of any kind concerning any publication, post, information,
                product or service offered or displayed on the Website.
              </p>
              <p>
                In respect that Platform, as a tool of communication and project
                management is used by unlimited number of Users, Seamless Source is not able
                to and does not review all the User Content, information and other
                data, Users post, send, submit, share on or via Platform and Website.
                Therefore, until You have announced a violation/ of Your Intellectual
                property rights, Seamless Source may simply not know about it. Still, in
                accordance with Terms and Conditions about the use of the Platform and
                Website,
              </p>
              <p>
                1) Any violation of Intellectual property rights is prohibited and is
                the subject to liability.
              </p>
              <p>
                2) any User who noticed the violation of Intellectual property right
                is obliged to report it to Seamless Source. In case of breach of such
                regulation, User, may be considered as an accomplice of such a
                violation.
              </p>
              <p>
                If you, as a rightful and true Intellectual property right owner,
                believe, that any materials, data, images, video, projects,
                information or other User Content, posted, send or submitted via or on
                the Platform or Website violates Your Intellectual property right, you
                should immediately inform Seamless Source Service via e-mail
                <a href="mailto:orders@seamlesssource.com" class="link-style">orders@seamlesssource.com</a> or by
                contact support on the website.
              </p>
              <p>
                You should specify the type of violation in Your claim/application and
                describe it so, that Seamless Source assuredly understands the essence of the
                problem. Please notice, that You should append and provide Seamless Source
                with any information, links, publication or other proof, that
                doubtlessly confirms Your intellectual property rights.
              </p>
              <p>
                Seamless Source will carefully and objectively check your complaint within 30
                (thirty) bank days, and inform You about a decision and actions taken.
              </p>
              <h3>
                <strong>
                  CODE OF CONDUCT FOR MANUFACTURERS AND SUPPLIERS OF SEAMLESS SOURCE LTD
                </strong>
              </h3>
              <p style="color: #000000;">
                <strong>Index</strong>
              </p>
              <p>1. Introduction</p>
              <p>2. No Forced Labour</p>
              <p>3. Freedom of association and collective bargaining</p>
              <p>4. Safe and hygienic working conditions</p>
              <p>5. Living wages are paid</p>
              <p>6. Working hours are not excessive</p>
              <p>7. No Discrimination</p>
              <p>8. No Child Labour</p>
              <p>9. Regular employment provided</p>
              <p>10. Traceability of production a. Home workers and subcontractors</p>
              <p>11. Harsh or inhumane treatment is not allowed</p>
              <p>12. Migrants legally employed</p>
              <p>13. Modern Slavery</p>
              <p>14. Environmental Protection</p>
              <p>15. Transparency (gift giving)</p>
              <p>16. Confidentiality of Information</p>
              <p>17. Code implementation</p>
              <p>
                18. Reference to National, Legislation, Conventions and Agreements
              </p>
              <p>19. Verification of compliance</p>
              <p>20. Remediation</p>
              <p>21. Whistle blowing Channel</p>
              <p>
                1.
                <strong>Introduction</strong>: The Code of Conduct is the minimum
                standard that all of Seamless Source Ltd (SEAMLESS SOURCE) factories, suppliers,
                licensees, partners and agents must meet. Suppliers must ensure these
                standards are implemented within their own operations and throughout
                their supply chain as a safeguard to their workers and the
                environment. As such, all of SEAMLESS SOURCE Suppliers are strictly required
                to adhere to the Code.
              </p>
              <p>
                2.
                <strong>No Forced Labour</strong>: Seamless Source shall not allow any form
                of forced or involuntary labour among their manufacturers or
                suppliers. Workers are not required to lodge &#8216;deposits&#8217; or
                their identity papers in order to gain employment and are free to
                leave employment with reasonable notice. (Governed by Conventions 29
                and 105 of International Labour Organization (ILO). Suppliers shall
                not engage in or support human trafficking.
              </p>
              <p>
                3.
                <strong>Freedom of association and collective bargaining</strong>:
                Employees have the right to join or form trade unions of their own
                choosing and to bargain collectively. The Supplier will adopt an open
                attitude towards the activities of unions and their organisational
                activities. Worker representatives will not be discriminated against
                and will have access to carry out their representative functions in
                the workplace. Where the rights to such unions are restricted under
                law, the Supplier will not hinder the development of alternate
                organisations for free association and bargaining. Where the rights to
                Freedom of Association and Collective Bargaining are restricted under
                law, the appropriate channels to ensure a reasonable and independent
                exercise of such rights must be designed according to ILO Conventions
                87,98 and 135. The Supplier shall effectively communicate this right
                to workers.
              </p>
              <p>
                4.
                <strong>Safe and Hygienic working conditions</strong>: Manufacturers
                and suppliers shall provide a safe and healthy workplace to their
                employees, ensuring minimum conditions of light, ventilation, hygiene,
                fire prevention, safety measures and access toa drinking water supply.
              </p>
              <p>
                Workers shall have access to clean toilets facilities and drinking
                water. Where necessary, facilities for food storage shall be provided.
                Accommodation, where provided, shall be clean and safe. Manufacturers
                and suppliers shall take the required steps to prevent accidents and
                injuries to health of their workers, by minimizing as much as possible
                the risks inherent to work. Workers shall receive regular and recorded
                health and safety training, and such training shall be repeated for
                new or reassigned workers. The Supplier shall appoint a senior
                management representative to be responsible for ensuring a safe and
                healthy environment for all workers and for implementing the health
                and safety elements of this standard, and establish systems to detect
                avoid or respond to these
              </p>
              <p>
                The Supplier shall provide, at its own expense, appropriate personal
                protective equipment to its workers and provide first aid training
                required by local law. Suppliers shall assist the worker in obtaining
                follow-up medical treatment following any work-related injury.
              </p>
              <p>
                The Supplier shall assess all the risks to new and expectant mothers
                arising out of their work activity and to ensure that all reasonable
                steps are taken to remove or reduce any risks to their health and
                safety.
              </p>
              <p>
                All Workers shall have the right to remove themselves from imminent
                serious danger without seeking permission from the Supplier.
              </p>
              <p>
                5.
                <strong>Living wages are paid</strong>: Wages and benefits will meet
                the minimum national legal standards or industry benchmark standards,
                whichever is higher. Wages shall always be enough to meet basic needs
                and to provide some discretionary income. All employees will be
                provided with written information about their employment conditions
                and wages before they enter employment and about the particulars of
                their wages for the pay period concerned each time they are paid.
                Deductions from wages as a disciplinary measure are not permitted. All
                disciplinary measures should be recorded.
              </p>
              <p>
                Manufacturers and suppliers shall also ensure that wages and any other
                allowances or benefits are paid on time and are rendered in full
                compliance with all applicable laws and specifically, that payments
                are made in the manner that best suits the workers this will be
                governed by ILO conventions 26 and 131.
              </p>
              <p>
                6.
                <strong>Working hours are not excessive</strong>: Manufacturers and
                suppliers shall adjust the length of the working day to the provisions
                of the applicable laws or of the collective bargain agreement
                applicable for the sector in question, if the latter affords greater
                protection for the workers. Manufacturers and suppliers shall not
                require their employees to work, as a rule of thumb, in excess of 48
                hours a week and workers shall be granted at least one day off for
                every 7-calendar day period on average. Overtime shall be voluntary,
                shall not exceed 12 hours per week. It shall not be demanded on a
                regular basis and shall always be compensated at a premium rate.
              </p>
              <p>
                7.
                <strong>No Discrimination</strong>: There will be no discrimination in
                hiring, treatment, compensation, access to training, promotion,
                termination or retirement based on race, caste, national origin,
                social origin, religion, age, disability, gender, gender identity,
                marital status, sexual orientation, birth, family responsibilities,
                social union membership or political affiliation. The Supplier shall
                not allow any behaviour that is threatening, abusive, exploitative, or
                sexually coercive including gestures, language, and physical contact,
                in the workplace and where applicable, in residences and other
                facilities provided by the company for use by personnel.
              </p>
              <p>
                8.
                <strong>No Child Labour</strong>: Manufacturers and suppliers shall
                not employ minors. SEAMLESS SOURCE defines minors as those persons who have
                not yet reached their 16th birthday. In cases where local legislation
                stipulates a higher minimum age, the higher limit shall apply. Persons
                with the ages between of 16 and 18 years will be considered young
                workers. Young workers shall not work during night hours or in
                hazardous conditions.
              </p>
              <p>
                9.
                <strong>Regular employment</strong>: Work performed must be on a basis
                of a recognised working relationship established through national law.
                Obligations to workers shall not be avoided through the use of
                labour-only contracting, sub-contracting or home-working arrangements
                or through apprenticeship schemes where there is no real intent to
                impart skills or provide regular employment.
              </p>
              <p>
                10. Traceability of production: Manufacturers and suppliers shall not
                assign any work to third parties without the prior written
                authorization of SEAMLESS SOURCE. Those who outsource any work shall be
                responsible for the enforcement of the Code by these third parties and
                their employees.
              </p>
              <p>
                a. Home workers and Subcontractors: Likewise, Manufacturers and
                suppliers shall apply the principles of this Code to any homeworker
                involved in their supply chain, and shall give transparency to the
                location and working conditions of said homeworkers.
              </p>
              <p>
                11.
                <strong>Harsh or in humane treatment is not allowed</strong>: There
                will be no exploitation of workers. This includes employing workers
                through dishonesty, the treatment of the worker whilst employed and
                the right to be free from oppressive treatment once the employment has
                been terminated. Physical, sexual, mental or verbal abuse is
                prohibited. As is the threat of any kind of abuse or other forms of
                intimidation.
              </p>
              <p>
                12.
                <strong>Migrants Legally employed</strong>: Migrant workers must be
                employed in accordance with the immigration and labour laws of the
                country of manufacture. The Supplier must have effective systems in
                place to verify the worker&#8217;s entitlement to work and their
                status in the country of manufacture. The Supplier must hold copies of
                documentation which verifies that the migrant worker can be legally
                employed. The migrant workers&#8217; right to freedom of movement and
                communications must not be interfered with and all original
                documentation necessary to exercise these rights must be left in the
                possession of the migrant worker.
              </p>
              <p>
                13.
                <strong>Modern Slavery</strong>: SEAMLESS SOURCE condemns the practice of
                Modern Slavery. The Supplier is required to ensure that all reasonable
                efforts are employed to eliminate Modern Slavery and deceptive
                practices in the recruitment of workers in their operations, in their
                subcontractors and within their supply chains including raw material
                producers. If the Supplier becomes aware of slavery within their
                supply chain, it must notify SEAMLESS SOURCE immediately and comply with the
                remediation obligations set out Section 20. The Supplier should make
                provisions to accommodate for individuals who are particularly
                vulnerable to these practices. This includes, but is not limited to;
                women, migrants and minorities.
              </p>
              <p>
                14.
                <strong>Environmental Protection</strong>: SEAMLESS SOURCE is committed to
                reducing our environmental impact. The Supplier should consider their
                environmental performance and procedures to minimise any negative
                impact on the environment, community and natural resources. The
                Supplier must operate with regard to national laws, regulations,
                administrative practices and policies relating to the preservation of
                the environment. All relevant international agreements, principles and
                standards in relation to the environment must be complied with. This
                includes, but is not limited to, the reduction and management of:
                greenhouse gas emissions, water consumption, waterborne effluent,
                chemicals, industrial waste and the use of non-renewable resources.
                The Supplier must provide relevant data if requested to do so by
                Seamless Source.
              </p>
              <p>
                15.
                <strong>Transparency</strong>: Manufacturers and suppliers shall carry
                out their activities in an honest, upright and transparent way,
                keeping for these purposes an appropriate accounting records system
                that facilitates the traceability of their decisions, as a preventive
                measure versus any type of corruption, bribe and extortion that might
                arise. Manufacturers and suppliers shall not offer, grant, request or
                accept any gifts or donations to/from SEAMLESS SOURCE employee’s customers
                which might infringe the provisions of the &#8220;SEAMLESS SOURCE Code of
                Conduct and Responsible Practices&#8221;. Manufacturers and suppliers
                shall not manipulate or influence their workers, nor shall they forge
                any files or records in order to alter the verification process
                regarding compliance with this Code. Manufacturers and suppliers shall
                neither offer nor accept remuneration of any kind which seeks, or may
                be perceived to seek, to affect the impartial judgment or the
                objectivity of such parties appointed by SEAMLESS SOURCE to carry out
                inspections and compliance audits in connection with this Code.
              </p>
              <p>
                16.
                <strong>Confidentiality of Information</strong>: Manufacturers and
                suppliers shall preserve the integrity and confidentiality of the
                information they may receive as a consequence of their commercial
                relationship with SEAMLESS SOURCE. The obligation of confidentiality will
                remain once the relation with SEAMLESS SOURCE is terminated and it will
                include the obligation to return any material related to the company
                held by the manufacturer or supplier.
              </p>
              <p>
                17.
                <strong>Code of Implementation</strong>: Manufacturers and suppliers
                shall implement and maintain programmes to set in motion this Code.
                They shall appoint a senior member of management who shall be
                responsible for the implementation and enforcement of this Code.
                Manufacturers and suppliers shall communicate the Code to all
                employees and those in any way involved in the SEAMLESS SOURCE Supply Chain.
                A copy of the Code, translated into the local language, shall be
                displayed in accessible locations to all workers.
              </p>
              <p>
                18.
                <strong>
                  Reference to National Legislation, Conventions and Agreements </strong
                >: The provisions of this Code constitute only minimum standards.
                Should national regulations or any other applicable Law or any other
                commitments undertaken or applicable, including collective bargaining
                agreements, govern the same issue, the provision which offers greater
                protection for workers shall apply. SEAMLESS SOURCE assumes, as part of its
                internal norms, the content of national and international Agreements
                and Conventions to which it has adhered, and that they are applied in
                its relationship with manufacturers and suppliers, committing to their
                promotion and compliance.
              </p>
              <p>
                19.
                <strong>Verification of Compliance</strong>: Manufacturers and
                suppliers shall authorize SEAMLESS SOURCE and/or any third parties the former
                might appoint, to monitor the appropriate enforcement of the Code. For
                these purposes, they shall provide the required means and the
                appropriate access to the facilities and documentation required to
                ensure this verification.
              </p>
              <p>
                20.
                <strong>Remediation</strong>: If the Supplier becomes aware of a
                breach of the Code, in any part of the supply chain, the Supplier must
                immediately notify SEAMLESS SOURCE and disclose any details that are deemed
                necessary by SEAMLESS SOURCE in order to accurately assess the problem. The
                Supplier will co-operate with SEAMLESS SOURCE and implement all measures
                necessary in order to resolve the issue at their own expense. SEAMLESS SOURCE
                reserves the right to terminate or blacklist any Supplier in breach of
                this Code.
              </p>
              <p>
                21.
                <strong>Whistle Blowing Channel</strong>: In order to ensure the
                enforcement of the Code of Conduct for Manufacturers and Suppliers,
                the Committee of Ethics can act at its own initiative or following a
                formal complaint made in good faith by a manufacturer, supplier or
                other interested third party that might have any direct relationship
                or commercial or professional interest with SEAMLESS SOURCE. To this end, any
                notices given pursuant to the provisions of this Code, either reports
                of any breach or enquiries regarding the Code&#8217;s interpretation
                or application can be addressed directly to the Committee of Ethics
                via:
              </p>
              <p>
                Address: Seamless Source, The Hive Maudslay Building, 50 Shakespeare St,
                Nottingham, NG1 4FQ, United Kingdom, Email:
                <a href="mailto:info@seamlesssource.com" class="link-style">info@seamlesssource.com</a>
              </p>
            </md-dialog-content>

            <md-dialog-actions>
              <md-button class="close-btn" @click="showDialog = false"
              >Close</md-button
              >
              <md-button v-if="!model.terms"
                class="agree-btn"
                @click="
                showDialog = false;
                model.terms = true;
              "
              >Agree</md-button
              >
              <md-button v-else
                class="agree-btn"
                @click="
                showDialog = false;
                model.terms = false;
              "
              >Disagree</md-button
              >
            </md-dialog-actions>
          </md-dialog>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from "../../auth";
import restAdapter from "../../restAdapter";
import defaultImage from "../../assets/person.png";
import notification from "../../notification";
import * as conf from "../../conf.yml";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import validationMixin from "../../validation/validation_mixin";
import BackgroundImage from "../../assets/background-images/BackgroundImage.vue";

/* eslint-disable */
export default {
  name: "CompleteProfile",
  mixins: [validationMixin],
  components: { BackgroundImage },

  data() {
    return {
      baseUrl: "",
      roles: [],
      showDialog: false,
      logoImg: require(`../../assets/seamless-source-banner.png`),
      model: {
        id:null,
        email: null,
        firstName: "",
        lastName: "",
        brandName: "",
        userType: null,
        imageUrl: defaultImage,
        terms: false,
        avatar: null
      }

    };
  },
  validations: {
    model: {
      firstName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      lastName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      userType: {
        required,
      },
      brandName: {
        required,
        maxLength: maxLength(150),
        minLength: minLength(3),
      },
      terms: {
        checked: (value) => value === true,
      },
    },
  },
  computed: {
    firstNameValidationError() {
      if (!this.$v.model.firstName.required) {
        return "First name is required";
      }

      if (!this.$v.model.firstName.minLength) {
        return "First name should contain at least 3 characters";
      }

      if (!this.$v.model.firstName.maxLength) {
        return "First name should less than 150 characters";
      }

      return null;
    },
    lastNameValidationError() {
      if (!this.$v.model.lastName.required) {
        return "Last name is required";
      }

      if (!this.$v.model.lastName.minLength) {
        return "Last name should contain at least 3 characters";
      }

      if (!this.$v.model.lastName.maxLength) {
        return "Last name should less than 150 characters";
      }

      return null;
    },
    userTypeValidationError() {
      if (!this.$v.model.userType.required) {
        return "The user type is required";
      }

      return null;
    },
    brandNameValidationError() {
      if (!this.$v.model.brandName.required) {
        return "Business or brand name is required";
      }

      if (!this.$v.model.brandName.minLength) {
        return "Business name should contain at least 3 characters";
      }

      if (!this.$v.model.brandName.maxLength) {
        return "Business name should less than 150 characters";
      }

      return null;
    },
    termsValidationError() {
      if (!this.$v.model.terms.checked && this.$v.model.terms.$dirty) {
        return "Please accept the terms and conditions";
      }

      return null;
    },
  },
  methods: {

    openTerms(){
      this.showDialog = true;
      this.model.terms = !this.model.terms;
    },

    save(){
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      const user = {
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        brandName: this.model.brandName,
        userType: this.model.userType
      }

      restAdapter
        .post("/api/complete_social_account/"+ this.model.id, user)
        .then((response) => {
          auth.updateAuth(response.data.success, true);
          notification.success("Welcome to " + conf.appName + "!");
          if (auth.user.userType === "supplier" && !auth.user.isRoleVerified) {
            this.$router.push({ name: "SupplierRoleSelection" });
            return;
          }
          this.$router.push(this.$route.query.redirect || "/app");
          window.location.reload();
        })
        .catch(err => {
          notification.error("Something went wrong, please try again later.")
        })
    },
    backToLogin() {
      auth.user.token = null;
      window.localStorage.removeItem('app-user-token');
      this.$router.push({
        path: `/login`,
      });
    },
  },
  mounted() {
    if (auth.user.userType && auth.user.userType !== 'user'){
      this.$router.push({ name: "Dashboard" });
    }
    this.model.id = auth.user.id;
    this.model.email = auth.user.email;
    this.model.firstName = auth.user.firstName;
    this.model.lastName = auth.user.lastName;
    this.model.avatar = auth.user.avatar;

    restAdapter.get("/api/register/display_user_role").then((response) => {
      this.roles = response.data.roles;
    });
  }
};
</script>
<style lang="scss" scoped>

.main-layout {
  width: 100vw;
  height: 100vh;
  padding: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-style{
  display: flex;
  justify-content: center;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.8px;
  margin-top: 15px;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  color: $primaryDarkest;
  display: flex;
  justify-content: center;
}

.complete-profile{
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow: auto;
}
.warning-text {
  color: #473068 !important;
}

.md-error{
  color: #473068 !important;
}

.email-input {
  background-color: $backgroundColor;
  border-radius: 5px;
  height: 50px;
  align-items: center;
  display: flex;
  margin: 0px -3px;
}

.wide-button {
  width: 100%;
  min-width: 20.188em;
  height: 50px;
  border-radius: 8px;
  margin-top: 10px;
}

.save-btn {
  color: #FFFFFF !important;
  background: $primaryDark;
  box-shadow: 0px 12px 14px rgba(91, 61, 135, 0.23);
  border-radius: 7px;
}

.cancel-btn {
  background: $paginateText;
  box-shadow: 0px 13.5045px 25.8836px rgba(61, 77, 135, 0.1);
  border-radius: 7px;
  margin-left: 8px !important;
}

.input-data {
    border-radius: 5px;
    border: none !important;
    height: 50px !important;
    padding-left: 12px !important;
    background-color: $backgroundColor !important;
    max-width: 100% !important;
    width: 100%;
    color: $primaryDarkest;
    opacity: 1; /* Firefox */
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13.9971px !important;
    line-height: 16px;
    letter-spacing: 2px;
}

.md-field.md-theme-default.md-has-value .md-input{
  -webkit-text-fill-color : $primaryDarkest !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  // font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.md-field.md-theme-default::before {
  background-color: transparent;
}

// .md-field.md-theme-default:after {
//     background-color: transparent !important;
// }

.md-field:after {
    height: 0px !important;
}

.md-field {
  margin: 0px !important;
}

.data-container{
  padding: 0px 16px !important;
}

.field-margin {
  margin-bottom: 7px;
  border-bottom: none !important;
  margin-left: auto;
  margin-right: auto;
}

.input-width {
  width: 100%;
  height: 100%;
  border-bottom: none;
  border: none;
  padding: 10px 20px;
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.input-data:focus {
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}

.input-data::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primaryDarkest !important;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.md-select::v-deep .md-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.input {
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.15)
  ); */
  background-color: $backgroundColor;
  border-radius: 4px;
  height: 50px;
}

.md-selected .md-list-item-content, .account-type-dropdown::v-deep .router-link-active .md-list-item-content {
  color: $infoMessage;
  background: rgba(243, 244, 248, 0.6);
}

.md-field.md-theme-default label {
  color: transparent !important;
}

.md-menu.md-select{
  overflow: hidden !important;
}

.md-list-item-content {
  color: $primaryDarkest;
}

.md-list-item-content:hover {
  color: $infoMessage;
}

.md-list.md-theme-default .md-selected .md-list-item-content {
    color: #473068 !important;
    background-color: #fafbfc !important;
}

.md-dialog-title{
  padding: 24px 100px 0;
}

.title-color{
  width: fit-content;
  color: $primaryDark !important;
  border-bottom: 1px solid $primaryDark;
}
.paragraph-color{
  color: $primaryDark;
  max-width: 100% !important;
}

.ol-style{
  margin-left: 15px;
}
.ol-style-long{
  margin-left: 20px;
}

.md-dialog-actions{
  min-height: 75px !important;
  padding: 35px 115px 30px 24px !important;
}

.md-dialog-content{
  padding: 0 100px 24px;
  line-height: 190%;
}

.close-btn{
    width: 126px;
    height: 32px;
    border: 1px solid $primaryDarkest;
    background: $popupWindow;
    color: $primaryDarkest;
    box-shadow: 6px 29px 22px rgb(62 59 99 / 18%);
    border-radius: 3px !important;
}

.agree-btn{
  width: 126px;
  height: 32px;
  border: 1px solid $primaryDarkest;
  background: $primaryDarkest !important;
  color: $paginateText !important;
  border-radius: 3px !important;
}

.md-dialog{
  background: rgba(15, 5, 29, 0.78);
  backdrop-filter: blur(4px);
}

.link-style {
    color: $linkStyle !important;
}

.md-dialog::v-deep .md-theme-default{
  max-width: 75%;
  max-height: 75%;
  background: $popupWindow;
  border-radius: 8px;
}

#checkboxLabel {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
  color: $primaryDarkest !important;
  cursor: pointer !important;
}

.login-link {
  color: $primaryDark !important;
  a {
    color: $primaryDark !important;
  }
}

.login-link:hover {
    text-decoration: underline;
}

.email-input-data{
  margin-left: 15px;
  color: $primaryDarkest !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 2px;
}

.form-container{
  max-height: 100vh;
  max-width: 100%;
  overflow-y: auto;
}

.account-type-dropdown {
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(55, 78, 138, 0.1);
  border-radius: 4px;
}

.account-type-dropdown::v-deep .md-menu-item .md-list-item-button:hover {
  background: $backgroundColor;
}

.md-select::v-deep .md-input, .md-select::v-deep .md-textarea {
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 2px;
}

.account-type-dropdown::v-deep .md-selected .md-list-item-content, .account-type-dropdown::v-deep .router-link-active .md-list-item-content {
  color: $infoMessage;
  background: rgba(243, 244, 248, 0.6);
}

.account-type-dropdown::v-deep .md-list-item-content {
  color: $primaryDarkest;
}

.account-type-dropdown::v-deep .md-list-item-content:hover {
  color: $infoMessage;
}

@media only screen and (max-width: 1280px) {
  .email-input{
    margin: 0px 5px;
  }
}

</style>

<style lang="scss">

.md-checkbox.md-theme-default.md-checked .md-checkbox-container {
    background-color: $popupWindow !important;
    border-radius: 4px !important;
}
.md-checkbox.md-theme-default .md-checkbox-container {
    background-color: $popupWindow !important;
    border-color: transparent !important;
    border-radius: 4px !important;
}
.md-checkbox.md-theme-default.md-checked .md-checkbox-container:after{
  border-color: $primaryDarkest !important;
}

.md-checkbox.md-theme-default.md-checked .md-ripple {
    color: #442D64;
}

</style>